import { Language } from "@languages/language";

const t: Language = {
  COUPON_DESC: "クーポンに関する説明",
  IMAGE: "画像",
  AUDIO: "音声",
  VIDEO: "動画",
  FILE: "ファイル",
  TEXT: "テキスト",
  ERROR_DATA_SAVING:
    "データを保存できませんでした。時間を置いてもう一度お試しください。",
  ERROR_DATA_FETCHING:
    "データを取得できませんでした。時間を置いてもう一度お試しください。",
  ERROR_DATA_DELETE:
    "データを削除できませんでした。時間を置いてもう一度お試しください。",
  ERROR_FILE_UPLOAD: "ファイルをアップロードすることができませんでした。",
  ERROR_RESET_PASSWORD_FAIL:
    "リセットに失敗しました。再度リクエストしてください。",
  DEFAULT_COUPON_DESCRIPTION:
    "- クーポンを使用するには、この画面をスタッフに提示してください。\n- 使用済みのクーポンはご利用になれません。また、お客さまの操作で誤って「使用済み」にしてしまった場合も利用できなくなります。",
  UPDATE_DATA_SUCCESS: "変更しました。",
  SAVE_DATA_SUCCESS: "保存しました。",
  MESSAGE_DELIVERY_LOG: "メッセージ配信履歴",
  // validations
  AT_LEAST_ONE_SYMBOL: "最低一つ記号を含めてください。",
  AT_LEAST_ONE_UPPERCASE_LETTER: "最低一つ大文字を含めてください。",
  PASSWORD_UNMATCHED: "パスワードが一致しません。",
  REQUIRED: "この項目は必須です。",
  EMAIL_INVALID: "正しい形式のメールアドレスを入力してください。",
  STRING_TYPE: "文字で入力してください。",
  NUMBER_TYPE: "数値で入力してください。",
  INVALID_URL: "正しい形式のURLを入力してください。",
  INVALID_TIME: "正しい時刻(24h)を入力してください。",
  INVALID_DATA: "不正なデータです",
  INVALID_DATE: "正しい形式の日付を入力してください",
  SHOULD_NOT_END_WITH_SLASH: "最後の/は含めないでください。",
  INVALID_HEX_COLOR: "正しい形式の色を選択してください。",
  END_MUST_BE_GREATER_THAN_START: "開始と終了を正しく入力してください。",
  INVALID_NUMBER_PLATE:
    "[地域][英数字][ひらがな][数字](例:品川500あ1234)形式で入力してください。",
  NO_WHITE_SPACE: "空白は含めないでください。",
  stringMin: (length: number) => `${length}文字以上で入力してください。`,
  stringMax: (length: number) => `${length}文字以下で入力してください。`,
  stringLength: (length: number) => `${length}文字で入力してください。`,
  numberMin: (n: number) => `${n}以上で入力してください。`,
  numberMax: (n: number) => `${n}以下で入力してください。`,
  arrayMin: (n: number) => `${n}件以上データが必要です。`,
  arrayMax: (n: number) => `データは最大${n}件です。`,
  acceptedFileTypes: (types) =>
    `${types.join(",")}形式のファイルのみ選択可能です。`,
  maxFileSize: (size, unit) =>
    `${size}${unit}以下のファイルを選択してください。`,
  mustBeNumberString: "半角数字を入力してください。",
  EMAIL_MISMATCH: "メールアドレスが一致しません。",
};
export default t;

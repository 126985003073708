import { z } from "zod";

import t from "@languages/ja";

export const loginSchema = z.object({
  idToken: z.string({ required_error: t.REQUIRED }),
  channelId: z.string({ required_error: t.REQUIRED }),
  liffId: z.string({ required_error: t.REQUIRED }),
});

export type LoginForm = z.infer<typeof loginSchema>;

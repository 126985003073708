import axios, { AxiosError } from "axios";

import isClient from "@utils/functions/isClient";

const Repository = axios.create({
  baseURL: isClient()
    ? `${process.env.NEXT_PUBLIC_API_URL}/api/liff`
    : `${process.env.API_URL}/api/liff`,
  responseType: "json",
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  retries: 3,
  retryCount: 0,
});
Repository.interceptors.request.use((config) => config);
Repository.interceptors.response.use(
  (res) => res,
  async (error: AxiosError) => {
    if (
      error.config &&
      error.response?.status === 401 &&
      (error.config.retryCount ?? 0) < (error.config.retries ?? 0)
    ) {
      // eslint-disable-next-line no-param-reassign
      error.config.retryCount = 1 + (error.config.retryCount ?? 0);
      try {
        return await axios(error.config);
      } catch (e) {
        return Promise.reject(e);
      }
    }
    return Promise.reject(error);
  }
);

export default Repository;

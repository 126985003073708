import "../styles/globals.css";
import { ReactElement, ReactNode, useEffect, useState } from "react";

import { NextPage } from "next";
import { useRouter } from "next/router";

import type { Liff } from "@line/liff";
import type { AppProps } from "next/app";

import AuthRepository from "@repositories/AuthRepository";
import { LoginForm, loginSchema } from "@schemas/loginSchema";

export type LiffPageProps = {
  liff: Liff | null;
  liffError: string | null;
};
export type NextPageWithLiff<P = {}, IP = P & LiffPageProps> = NextPage<IP> & {
  getLayout: (page: ReactElement) => ReactNode;
};
type AppPropsWithLiff = AppProps & {
  getLayout: (page: ReactElement) => ReactNode;
} & {
  Component: NextPageWithLiff;
};

const MyApp = ({ Component, pageProps }: AppPropsWithLiff) => {
  const [liffObject, setLiffObject] = useState<Liff | null>(null);
  const [liffError, setLiffError] = useState<string | null>(null);
  const router = useRouter();
  const liffInitialize = async () => {
    const liffId = router.query.baseLiffId;
    if (liffId && typeof liffId === "string") {
      try {
        const liffInstance = await import("@line/liff");
        const liff = liffInstance.default;
        await liff.init({ liffId });
        setLiffObject(liff);
        const form = {
          idToken: liff.getIDToken(),
          channelId: liff.getDecodedIDToken()?.aud,
          liffId,
        };
        const result = loginSchema.safeParse(form);
        if (result.success && liff.isLoggedIn()) {
          await AuthRepository.login(form as LoginForm);
        }
        if (!result.success) {
          console.error(result.error);
        }
      } catch (error) {
        // @ts-ignore
        setLiffError(error.toString());
      }
    }
  };
  useEffect(() => {
    liffInitialize();
  }, [router.query.baseLiffId]);

  // eslint-disable-next-line no-param-reassign
  pageProps.liff = liffObject;
  // eslint-disable-next-line no-param-reassign
  pageProps.liffError = liffError;
  const getLayout = Component.getLayout ?? ((page) => page);
  return getLayout(<Component {...pageProps} />);
};

export default MyApp;

import { AxiosResponse } from "axios";

import { LineUser } from "@entities/LineUser";
import Repository from "@repositories/Repository";
import { LoginForm } from "@schemas/loginSchema";

const resource = "auth";
export const fetchRoutes = {
  me: `${resource}/me`,
};

export default class AuthRepository {
  static login(form: LoginForm): Promise<AxiosResponse<void>> {
    return Repository.post(`${resource}/login`, form);
  }

  static me(): Promise<AxiosResponse<LineUser>> {
    return Repository.get(fetchRoutes.me);
  }
}
